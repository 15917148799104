import { render, h } from 'preact';
import logo from "../assets/reveri.png";
import style from "../assets/style.css";


/** @jsx h */

const App = () => {
	return (
		<div>
            <img className="logo" src={logo}></img>
            <h2 className="description">Reveri, eller dagdröm är en fantasi som upplevs i vaket tillstånd, till skillnad från drömmen upplevd under sömn. Dagdrömmar består oftast av trevliga tankar, förhoppningar och ambitioner.</h2>
		</div>
	)
}

render(<App />, document.getElementById("app"));